@use "../../styles/colors";

.footer {
  padding: 32px 0 24px 0;
  background: colors.$black;
  color: colors.$white;

  .footer-info-container {
    flex-direction: column;
    gap: 24px;
  }

  .copyright {
    color: colors.$grey;
  }

  .privacy-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .privacy-policy {
    color: colors.$white;
    margin-top: 1rem;
    text-decoration: none;
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .footer {
    .footer-info-container {
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: space-between;
      width: 92%;
      margin: 0 auto;
    }
    .container {
      flex-direction: column-reverse;
    }
    .footer-actions-wrapper {
      width: 100%;
    }
    .privacy-wrapper {
      flex-direction: column;
      align-items: center;
    }
    .privacy-policy {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }
}
