@use "./styles/colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: colors.$white;
}
.footer-banner-container {
  background-color: colors.$jamun-80;

  .custom-footer-banner {
    height: 491px;
    display: flex;
    overflow: hidden;
  }

  .banner-title {
    font-size: 48px;
    font-weight: 300;
    width: 580px;
    color: colors.$white;
  }
}

@media (max-width: 992px) {
  .footer-banner-container {
    padding-top: 30px;
    .custom-footer-banner {
      height: 100%;
      flex-direction: column;
    }
    .banner-title {
      font-size: 37px;
      width: 92%;
      line-height: 44px;
    }
    .banner-poster {
      width: 92%;
      object-fit: contain;
      margin: 80px 0 0 0;
    }
  }
}
