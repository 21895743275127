@use '../../../styles/colors';

.reaches-heading {
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  margin-top: 64px;
  // width: 950px;
}
.reaches-playstore {
  margin-top: 97px;
}
.features-row {
  margin: 40px 0px 40px 0px;
}
.features-title {
  margin: 16px 0px 0px 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.features-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin: 4px 0px;
}
.features-icon {
  position: relative;
  &:before {
    background-image: url("/assets/images/line_indicator.svg");
    content: "";
    height: 4px;
    position: absolute;
    width: calc(100% - 95px);
    // opacity: 0.4;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px;
  }
}
.pictures-img {
  margin: 64px auto 0px auto;
  width: 100%;
  height: 540px;
  object-fit: contain;
  border-radius: 8px;
}
.picture-heading {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
}
.picture-subtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0px;
}
.picture-download {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}
.features-col:nth-child(1) {
  padding-left: 0px;
}
.features-col:nth-child(4) {
  .features-icon {
    &::before {
      display: none;
    }
  }
}

.feature-container{
  margin-top: 200px;
  margin-bottom: 100px;
}
.benefits-container{
  width: 50%;
}
.benefits-heading{
  font-size: 28px;
  font-weight: 700;
  color: colors.$jamun-80;
}
.custom-features-items-wrapper{
  height: 100%;
}
.feature-sample-img{
  height: 550px;
  object-fit: contain;
}
.feature-description-wrapper{
  margin-left: 50px;
  padding-right: 20px;
}
.benefit-title{
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 10px;
}
.benefit-description{
  font-size: 20;
  font-weight: 400;
}
.info-container{
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .features-col:nth-child(3) {
    .features-icon {
      &::before {
        display: none;
      }
    }
  }
  .features-col {
    padding: 0px;
  }
}
@media (max-width: 992px) {
  .reaches-heading {
    width: 92%;
    font-size: 24px;
    line-height: 32px;
    margin: 32px auto 0 auto;

  }
  .features-row {
    margin: 20px auto 20px auto;
    width: 92%;
  }
  .features-icon {
    &:before {
      background-image: url("");
    }
  }
  .features-col {
    display: flex;
    margin: 20px 0px;
    padding: 0px;
    flex-direction: column;
    .features-text {
      .features-title {
        margin: 0px;
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
      }
    }
  }

  .features-col:last-child {
    .features-icon {
      &::before {
        display: none;
      }
    }
  }
  .features-icon svg {
    position: relative;
    z-index: 2;
  }
  .pictures-img {
    margin: 29px 0px;
    height: 300px;
    object-fit: contain;
    width: 100%;
  }
  .picture-heading {
    margin: 12px 0px;
    font-size: 24px;
    line-height: 32px;
  }
  .picture-subtitle {
    margin: 8px 0px;
    font-size: 16px;
    line-height: 26px;
  }
  .feature-container{
    margin-top: 20px;
    margin-bottom: 40px;
    flex-direction: column;
    text-align: center;
  }
  .benefits-container{
    width: 100%;
  }
  .benefits-heading{
    width: 100% !important;
    margin: 0 auto;
    size: 20px;
    margin-bottom: 20px;
  }
  .custom-features-items-wrapper{
    display: none !important;
  }
  .feature-sample-img{
    width: 92% !important;
    margin: 0 auto;
    height: auto;
  }

  .info-container{
    width: 92%;
    margin: 0 auto;
    display: block;
    text-align: start;
    margin-bottom: 20px;
    .info-container_heading{
      font-size: 32px;
      line-height: 48px;
      font-weight: 900;
    }
    .info-container_description{
      font-size: 18px;
      line-height: 24px;
    }
    .info-container_link{
      font-size: 20px;
      font-weight: 700;
      color: colors.$jamun-80;
      text-decoration: none;
      margin: 0 auto;
    }
  }
}
