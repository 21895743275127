@use "../../styles/colors";
.navbar {
  height: 72px;
  font-size: 1rem;
  background: colors.$jamun-80;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
  color: colors.$white;
  .language-icon {
    cursor: pointer;
  }
  .nav-link {
    color: inherit;
  }
  .logo-title {
    font-size: 24px;
    font-weight: 600;
  }
  .watch-video-link{
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: colors.$white;
    display: flex;
    align-items: center;

    .watch-video-icon{
      width: 19px;
      margin-left: 10px;
    }
  }
  .for-mob{
    display: none;
  }
  .nav-menu-container{
    .nav-list-items{display: flex;}
    .close-hamburger{display: none;}
  }
  .hamburger_icon{
    color: colors.$white;
    display: none;
  }
}

@media (max-width: 992px) {
  .navbar{
    .logo-title {
      font-size: 20px;
    }
    .watch-video-link{
      display: none;
      font-size: 16px;
      font-weight: 400;
      color: colors.$black;
      margin-top: 10px;
      .watch-video-icon{
        color: colors.$black;
      }
    }
    .for-mob{
      display: block;
    }
    .nav-menu-container{
      position: fixed;
      top: 0;
      background-color: colors.$white;
      width: 100%;
      height: 250px;
      left: 0;
      color: colors.$black;
      .nav-list-items{
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 170px;
      }
      .close-hamburger{
        display: block; 
        width: 14px;
        margin: 20px
      }
    }
    .hamburger_icon {
      display: inline-block;
      width: 18px;
      height: 12px;
      margin-left: 30px;
    }
    .hide-menu{display: none;}
  }
}