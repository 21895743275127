@use '../../styles//colors';

.call-actions-container {
    &.cta-buttons {
        display: flex;
        gap: 16px;
    }
    .btn {
        min-width: 224px;
        height: 56px;
        font-size: 16px;
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        &.btn-light {
            &:hover {
                background-color: transparent;
                color: colors.$white;
                svg {
                    fill: colors.$white;
                }
            }
        }
        &.btn-outline-light {
            svg {
                fill: colors.$white;
            }
            &:hover svg {
                fill: colors.$black;
            }
        }
        svg {
            height: 18px;
            width: 18px;
        }
    }    
}

@media (max-width: 992px){
    .call-actions-container{
        &.cta-buttons {
            flex-direction: column;
        }
    }
}
