@import "./styles.scss";
@import '../../../styles/_colors.scss';

.sliderContent {
  display: flex !important;
  flex-direction: row;
  @media screen and (max-width: $medium) {
    width: 100%;
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    // align-items: center;
    position: relative;
  }
}

.bContent {
  text-align: left;
  margin-top: 32px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    margin-left: 0;
  }
}
.home-slider-header{
  font-size: 28px;
  color: $jamun-80;
  font-weight: 700;
}
.bTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
}
.bSubtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.bSliderImage {
  max-width: 100%;
  img {
    object-fit: fill;
    height: 512px;
    width: 512px;
    @media screen and (max-width: $medium) {
      height: 400px;
      width: 350px;
    }
  }
  @media screen and (max-width: $medium) {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

.slider-link{
  font-size: 20px;
  text-decoration: none;
  color: $jamun-80;
  font-weight: 700;
}

.bgTitle {
  max-width: 27.5rem;
  @media screen and (max-width: $medium) {
    max-width: 100%;
  }
}

.dMsg {
  max-width: 28rem;
  @media screen and (max-width: $medium) {
    max-width: 100%;
  }
}

.dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: -3rem;
  position: relative;
  width: 100%;
  justify-content: center;

  @media only screen and (min-width: $medium) and (max-width: $large) {
    width: 50%;
    justify-content: unset;
    padding-left: 66%;
  }

  @media screen and (max-width: $medium) {
    margin-left: auto;
    margin-right: auto;
    margin-top: -22rem;
    justify-content: center;
  }
  @media screen and (max-width: 360px) {
    margin-top: -24rem;
  }
  li {
    margin-right: 6px;
    &:before {
      content: "";
      height: 8px;
      width: 8px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.24);
      border-radius: 6px;
      cursor: pointer;
      transition: all 300ms ease;
    }
  }

  .active{
    &:before{
      background-color: $jamun-80;
    }
  }
}

@media(max-width: 992px){
  .custom-slider-style{
    display: none;
  }
}
