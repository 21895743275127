@use "../../styles/colors";

.privacy {
  padding-top: 113px;
  
  .heading {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 3rem;
    font-weight: bold;
    color: colors.$black;
    text-align: center;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    margin: 35px 0px;
  }
 a {
    color: colors.$white;
}
  .text {
    padding: 0;
    font-size: 16px;
    line-height: 47px;
    color: colors.$black;
    margin-top: 1rem;
    position: relative;
    text-align: justify;
  }
  .li_text{
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    color: colors.$black;
    margin-top: 1rem;
    position: relative;
    .li_subtext{
      line-height: 35px;
    
    }
  }

  ol {
    counter-reset: item;
  }
  li {
    display: flex;
  }
  li:before {
    content: counters(item, ".") ".";
    counter-increment: item;
    padding-right: 13px;
  }
}
@media (max-width: 992px) {
  .privacy {
    .heading {
      font-size: 22px;
      margin-bottom: 2rem;
      line-height: 36px;
    }
  }
}
