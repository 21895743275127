@use "../../../styles//colors";

.hero-container {
  background-color: colors.$jamun-80;
  height: 600px;
  color: colors.$white;
  .left-section {
    display: flex;
    flex-direction: column;
    gap: 86px;
  }

  .hero-heading {
    max-width: 445px;
    text-align: left;
  }

  h1 {
    font-size: 72px;
  }
}

@media (max-width: 992px) {
  .hero-container{
    height: 400px;
    .left-section {
      width: 90%; 
      margin: 0 auto;
      gap: 50px;
    }
    h1{ font-size: 36px; }
    .sample-poster-image{ display: none;}
  }
}