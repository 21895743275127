@use "../../../styles/colors";

$large: 1400px;
$medium: 993px;

.benefitsSlider{
  height: 550px;
}
.sliderContent {
  display: flex !important;
  flex-direction: row;
  @media screen and (max-width: $medium) {
    width: 100%;
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    // align-items: center;
    position: relative;
  }
}

.bContent {
  margin-top: 32px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    margin-left: 0;
  }
}
.bTitle {
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
}
.bSubtitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0px;
  margin-left: 4.875rem;
  @media screen and (max-width: $medium) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.bgTitle {
  max-width: 27.5rem;
  @media screen and (max-width: $medium) {
    max-width: 100%;
  }
}

// Products
.pheading,
.iheading {
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  // margin: 16px 0px;
  width: 730px;
}
.psubheading,
.isubheading {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  margin: 16px 0px;
  width: 730px;
}

.pImg {
  margin: 7.5px 0px;
  position: relative;

  &:hover .overlay {
    opacity: 1;
  }
  img {
    height: auto;
    width: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 7.5px 14px;
    border-radius: 9px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.64);
    height: auto;
    display: flex;
    align-items: center;
  }

  .ptext {
    color: colors.$white;
    text-align: left;
    padding: 0px 32px;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    width: 329px;
  }
}
.pSlider {
  .content {
    width: 250px;
    margin-right: 24px;
    margin-left: 24px;
  }
  img {
    height: 276px;
    margin: auto;
  }
  .pSliderTxt {
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    margin: 16px 0px 27px 0px;
  }
}
.products {
  .slick-slider {
    display: none;
  }
}
// Ingredients
.imobile {
  .mobile_img {
    height: 500px;
    filter: drop-shadow(4px 4px 60px rgba(0, 0, 0, 0.4));
  }
  .ilist {
    display: flex;
    margin: 24px 0px;
    img {
      width: 72px;
      height: 72px;
      margin: 12px 40px 0px 0px;
    }
    .content {
      margin: 8px 0px;
      .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }
      .subtitle {
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        width: 362px;
      }
    }
  }
}
@media (max-width: 992px) {
  // Products, Ingredients
  .pheading,
  .iheading {
    width: 100%;
    font-size: 32px;
    line-height: 48px;
    padding-top: 32px;
  }
  .psubheading,
  .isubheading {
    width: 100%;
    font-size: 18px;
    line-height: 32px;
  }
  .pImg {
    .ptext {
      font-size: 18px;
      line-height: 24px;
      width: 202px;
    }
  }
  .products {
    .row {
      display: none;
    }
    .slick-slider {
      display: block;
    }
  }
  .imobile {
    .mobile_img {
      width: 100%;
      margin: 20px auto;
      object-fit: contain;
    }
    .ilist {
      img {
        margin: 14px 40px 0px 0px;
      }
      .content .subtitle {
        width: 100%;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}
